<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import { formatDate } from '~/constants'

const router = useRouter()
const { user } = useAuthStore()
if (user.id) {
    router.push(`/order/${formatDate(new Date())}`)
}
</script>

<div />
