import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import generatedRoutes from 'virtual:generated-pages'
import { setupLayouts } from 'virtual:generated-layouts'

import '@unocss/reset/tailwind.css'
import './styles/main.scss'
import 'uno.css'
import ElementPlus from 'element-plus'

// element plus component styles
import 'element-plus/theme-chalk/src/notification.scss'

import ru from 'element-plus/es/locale/lang/ru'
import 'v-calendar/dist/style.css'
import VCalendar from 'v-calendar'
import vueAwesomeSidebar from 'vue-awesome-sidebar'
import 'vue-awesome-sidebar/dist/vue-awesome-sidebar.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './App.vue'

const app = createApp(App)
app.use(ElementPlus, {
    locale: ru,
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus)
app.use(VCalendar, {})
app.use(vueAwesomeSidebar)
// setup up pages with layouts
const routes = setupLayouts(generatedRoutes)
const router = createRouter({ history: createWebHistory(), routes })
app.use(router)

// install all modules under `modules/`
// @ts-expect-error
Object.values(import.meta.globEager('./modules/*.ts')).map(i => i.install?.({ app, router, routes }))

app.mount('#app')
