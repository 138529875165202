import type { UseFetchOptions } from '@vueuse/core'
import { createFetch } from '@vueuse/core'
import { ofetch } from 'ofetch'

const BASE_URL = '/api'
const TIMEOUT = 5_000

const options: UseFetchOptions = {
    timeout: TIMEOUT,
    immediate: true,
    onFetchError: ctx => {
        const { error = { message: '' } } = (ctx.data || {})
        if (error.message) {
            ctx.error.message = error.message
            ElMessage({ message: error.message, type: 'error', duration: 10000 })
        }
        return ctx
    },
}
const fetchOptions: RequestInit = {
    credentials: 'same-origin',
}

export const useApi = createFetch({ baseUrl: BASE_URL, options, fetchOptions })
export const useApiLazy = createFetch({ baseUrl: BASE_URL, options: { ...options, immediate: false }, fetchOptions })

export const api = ofetch.create({
    timeout: TIMEOUT,
    baseURL: BASE_URL,
})
