import { acceptHMRUpdate, defineStore } from 'pinia'
import { set } from '@vueuse/core'

interface User {
    id: number
    name: string
    email: string
    role: string
    notify: boolean
    notifyTg: boolean
    notifyOrder: boolean
    isConfirmed: boolean
    isDeleted: boolean
    code: string
    team: { id: number; name: string; isDeleted: boolean; canAddGuest: boolean; canAddConsumables: boolean }
    teamId: number | null
    office: { id: number; label: string }
}

export const useAuthStore = defineStore('auth', () => {
    /** идёт загрузка пользователя */
    const pending = ref(true)
    const user = ref<User>({
        id: 0,
        name: '',
        email: '',
        role: 'guest',
        notify: false,
        notifyTg: false,
        notifyOrder: false,
        isConfirmed: false,
        isDeleted: true,
        code: '',
        team: {
            id: 0,
            name: '',
            isDeleted: false,
            canAddGuest: false,
            canAddConsumables: false,
        },
        teamId: 0,
        office: { id: 0, label: '' },
    })
    /** ошибка загрузки пользователя */
    const error = ref('')

    const isGuest = computed(() => user.value.role === 'guest')

    async function auth() {
        await api('/users/me', {
            method: 'get',
            retry: 2,
            retryDelay: 1000,
            // ? retryStatusCodes: [500, 503, 502],
        }).then(r => {
            set(user, r.user)
        }).catch(err => {
            set(error, err)
        })
    }

    return { pending, error, user, auth, isGuest }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
}
