<script lang="ts" setup>
import { get, set } from '@vueuse/core'
import { dayjs } from 'element-plus'

const { user } = useAuthStore()
const close = ref<boolean>(false)
const collapse = ref<boolean>(false)
const width = computed<string>(() => (close.value ? '60px' : '250px'))
const isLargeScreen = useMediaQuery('(min-width: 901px)')
const widthMiniMenu = computed<string>(() => (get(isLargeScreen) ? '65px' : '0px'))
const position = computed<string>(() => (get(isLargeScreen) ? 'relative' : 'absolute'))

if (!get(isLargeScreen)) {
    set(collapse, true)
}
const windowSmall = ref(window.innerWidth < 901)

const sideBar = ref(null)

watch(isLargeScreen, () => {
    if (!get(isLargeScreen)) {
        set(close, true)
        set(collapse, true)
        set(windowSmall, true)
    } else {
        set(close, true)
        set(collapse, false)
        set(windowSmall, false)
    }
})

watch(collapse, () => {
    set(close, get(isLargeScreen) && !get(close))
})

const menu = computed(() => {
    let wis = windowSmall.value
    const currentDate = dayjs(new Date()).format('YYYY-MM-DD')
    let list = [
        {
            name: 'Заказ',
            icon: { class: 'i-carbon-license-draft' },
            href: `/order/${currentDate}`,
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Все заказы',
            icon: { class: 'i-carbon-delivery' },
            href: '/end-orders',
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Меню',
            icon: { class: 'i-carbon-catalog' },
            href: '/menu',
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Номенклатура',
            icon: { class: 'i-carbon-bar' },
            href: '/items',
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Категории',
            icon: { class: 'i-carbon-expand-all' },
            href: '/categories',
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Профиль',
            icon: { class: 'i-carbon-user-avatar' },
            href: '/profile',
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Пользователи',
            icon: { class: 'i-carbon-events' },
            href: '/users',
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Команды',
            icon: { class: 'i-carbon-assembly-cluster' },
            href: '/teams',
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Офисы',
            icon: { class: 'i-carbon-building' },
            href: '/offices',
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Настройки',
            icon: { class: 'i-carbon-settings' },
            href: '/settings',
            collapseOnClick: wis,
            miniActiveClass: true,
        },
        {
            name: 'Логи',
            icon: { class: 'i-carbon-cloud-logging' },
            href: `/logs?dates=${currentDate},${currentDate}`,
            collapseOnClick: wis,
            miniActiveClass: true,
        },
    ]

    if (user.role !== 'ADMIN') {
        list = list.filter(i => ['Заказ', 'Профиль'].includes(i.name))
    }
    return list
})

function menuToggle() {
    set(close, !get(close))
    set(collapse, !get(isLargeScreen) && !get(collapse))
}
</script>

<template>
    <div class="l-body">
        <div class="l-header">
            <div class="l-burger" @click="menuToggle">
                <svg
                    height="16px" viewBox="0 0 25 16" width="25px" x="0px"
                    xmlns="http://www.w3.org/2000/svg" y="0px"
                >
                    <g fill="#4E4E4E">
                        <rect height="2" width="25" />
                        <rect height="2" width="25" y="7" />
                        <rect height="2" width="25" y="14" />
                    </g>
                </svg>
            </div>
            <a href="/" style="flex-shrink: 0;">
                <svg height="32" viewBox="0 0 84 32" width="84" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.6">
                        <g stroke="#9d191f">
                            <path d="m33,12s1,5.43,1,7c0,4-8,4-8,4,0,0-8,0-8-4,0-1.57,1-7,1-7" />
                            <path d="m2,10v9c0,3,3,3,3,3h4s3,0,3-3" />
                            <path d="m40,22v-9c0-3,3-3,3-3h4s3,0,3,3v9" />
                            <path d="m72,22v-9c0-3,3-3,3-3h4s3,0,3,3v9" />
                            <path d="m66,13c0-3-3-3-3-3h-4s-3,0-3,3v6c0,3,3,3,3,3h4s3,0,3-3" />
                            <line x1="72" x2="72" y1="8" y2="13" />
                        </g>
                        <g stroke="#404040">
                            <line x1="26" x2="26" y1="4" y2="6" />
                            <path d="m31,8s-2-2-5-2-5,2-5,2" />
                        </g>
                    </g>
                </svg>
            </a>
            <router-link class="header--user" to="/profile" title="перейти в профиль">
                <span v-if="user.name" class="header--user--name">
                    {{ user.name }}
                </span>
                <span v-if="user.email" class="header--user--mail">
                    {{ user.email }}
                </span>
                <span v-else> - </span>
            </router-link>
        </div>

        <!-- -->
        <div class="l-container">
            <VueAwesomeSideBar
                ref="sideBar"
                v-model:collapsed="collapse"
                v-model:miniMenu="close"
                :close-on-click-out-side="!isLargeScreen"
                :menu="menu"
                :over-layer-on-open="!isLargeScreen"
                :position="position"
                :width="width"
                :width-mini-menu="widthMiniMenu"
                vue-router-enabel
            />
            <div class="l-container--main">
                <el-tag
                    v-if="!user.isConfirmed || user.isDeleted"
                    effect="dark"
                    size="large"
                    style="margin-top: 10px; font-size: large; margin-bottom: 12px"
                    type="danger"
                >
                    Аккаунт не подтвержден или удалён
                </el-tag>
                <RouterView />
            </div>
            <!-- -->
        </div>
    </div>
</template>
