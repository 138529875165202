const __pages_import_0__ = () => import("/src/pages/order/index.vue");
const __pages_import_1__ = () => import("/src/pages/auth/restore-password.vue");
const __pages_import_2__ = () => import("/src/pages/auth/restore-email.vue");
const __pages_import_3__ = () => import("/src/pages/auth/registration.vue");
const __pages_import_4__ = () => import("/src/pages/auth/login.vue");
const __pages_import_5__ = () => import("/src/pages/users.vue");
const __pages_import_6__ = () => import("/src/pages/teams.vue");
const __pages_import_7__ = () => import("/src/pages/settings.vue");
const __pages_import_8__ = () => import("/src/pages/profile.vue");
const __pages_import_9__ = () => import("/src/pages/offices.vue");
const __pages_import_10__ = () => import("/src/pages/menu_old.vue");
const __pages_import_11__ = () => import("/src/pages/menu.vue");
const __pages_import_12__ = () => import("/src/pages/logs.vue");
const __pages_import_13__ = () => import("/src/pages/items.vue");
import __pages_import_14__ from "/src/pages/index.vue";
const __pages_import_15__ = () => import("/src/pages/end-orders.vue");
const __pages_import_16__ = () => import("/src/pages/categories.vue");
const __pages_import_17__ = () => import("/src/pages/[...all].vue");
const __pages_import_18__ = () => import("/src/pages/hi/[name].vue");
const __pages_import_19__ = () => import("/src/pages/order/[date].vue");

const routes = [{"name":"order","path":"/order","component":__pages_import_0__,"props":true},{"name":"auth-restore-password","path":"/auth/restore-password","component":__pages_import_1__,"props":true,"meta":{"layout":"modal"}},{"name":"auth-restore-email","path":"/auth/restore-email","component":__pages_import_2__,"props":true},{"name":"auth-registration","path":"/auth/registration","component":__pages_import_3__,"props":true,"meta":{"layout":"modal"}},{"name":"auth-login","path":"/auth/login","component":__pages_import_4__,"props":true,"meta":{"layout":"modal"}},{"name":"users","path":"/users","component":__pages_import_5__,"props":true},{"name":"teams","path":"/teams","component":__pages_import_6__,"props":true},{"name":"settings","path":"/settings","component":__pages_import_7__,"props":true},{"name":"profile","path":"/profile","component":__pages_import_8__,"props":true},{"name":"offices","path":"/offices","component":__pages_import_9__,"props":true},{"name":"menu_old","path":"/menu_old","component":__pages_import_10__,"props":true},{"name":"menu","path":"/menu","component":__pages_import_11__,"props":true},{"name":"logs","path":"/logs","component":__pages_import_12__,"props":true},{"name":"items","path":"/items","component":__pages_import_13__,"props":true},{"name":"index","path":"/","component":__pages_import_14__,"props":true},{"name":"end-orders","path":"/end-orders","component":__pages_import_15__,"props":true},{"name":"categories","path":"/categories","component":__pages_import_16__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_17__,"props":true,"meta":{"layout":404}},{"name":"hi-name","path":"/hi/:name","component":__pages_import_18__,"props":true},{"name":"order-date","path":"/order/:date","component":__pages_import_19__,"props":true}];

export default routes;