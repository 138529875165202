import type { UserModule } from '~/types'

export const install: UserModule = ({ router }) => {
    router.beforeEach(async (to, _) => {
        const { isGuest, auth } = useAuthStore()

        // isGuest может измениться
        if (to.path.startsWith('/auth')) {
            if (isGuest) {
                return true
            }
            return to.query.from ? String(to.query.from) : '/'
        }

        if (isGuest) {
            await auth()
        }
        if (useAuthStore().isGuest) {
            return `/auth/login?from=${to.path}`
        }
        return true
    })
}
