export function tableRowDeleted({ row }: { row: any }) {
    if (row.isDeleted) {
        return 'warning-row'
    }
}

export function arrayDifferences(a1: any[], a2: any[]) {
    return a1.filter(i => !a2.includes(i)).concat(a2.filter(i => !a1.includes(i)))
}

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0')
}
export function formatDate(date: Date) {
    return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-')
}

export function changeYear(date: Date, year: number) {
    return new Date(date.setFullYear(date.getFullYear() + year))
}

export function isDateCorrect(date: string) {
    return new Date(date).toString() !== 'Invalid Date' && changeYear(new Date(), -1) < new Date(date) && new Date(date) < changeYear(new Date(), 1)
}
